import React from "react";
import Head from "next/head";

interface MetaFieldsProps {
  page: any;
  globalSettings: any;
  pageRouter: any;
  env: {
    SITE_URL: string;
    ROBOTS: string;
  };
}

const MetaFields: React.FC<MetaFieldsProps> = ({
  page,
  globalSettings,
  pageRouter,
  env,
}) => {
  const pageData = page.data;
  const settingsData = globalSettings.data;

  const metaTitle =
    pageData.meta_title !== settingsData.meta_title
      ? `${pageData.meta_title} | ${settingsData.meta_title}`
      : settingsData.meta_title;

  const ogOrMetaTitle = pageData.og_title
    ? pageData.og_title
    : pageData.meta_title;
  const ogTitle =
    ogOrMetaTitle !== settingsData.meta_title
      ? `${pageData.meta_title} | ${settingsData.meta_title}`
      : settingsData.meta_title;

  const twitterOrMetaTitle = pageData.twitter_title
    ? pageData.twitter_title
    : pageData.meta_title;
  const twitterTitle =
    twitterOrMetaTitle !== settingsData.meta_title
      ? `${pageData.meta_title} | ${settingsData.meta_title}`
      : settingsData.meta_title;

  const metaDescription = `${
    pageData.meta_description
      ? pageData.meta_description
      : settingsData.og_description
  }`;

  const ogDescription = pageData.og_description
    ? pageData.og_description
    : metaDescription;

  const twitterDescription = pageData.twitter_description
    ? pageData.twitter_description
    : metaDescription;

  const ogImage = `${
    pageData.og_image && pageData.og_image.url
      ? pageData.og_image.url
      : settingsData.meta_og_image.url
  }`;

  const twitterImage =
    (pageData.twitter_image && pageData.twitter_image.url
      ? pageData.twitter_image.url
      : settingsData.twitter_image.url) || "";

  const siteUrl = env.SITE_URL || "";
  let pageUrl = siteUrl;
  if (pageRouter && pageRouter.asPath.length > 0) {
    pageUrl = siteUrl + pageRouter.asPath;
  }

  const robots = env.ROBOTS || "noindex, nofollow";

  return (
    <Head>
      <meta name="viewport" content="width=device-width, initial-scale=1" />

      <title key="_title">{metaTitle}</title>
      <meta key="meta_title" name="title" content={metaTitle} />

      <meta
        key="meta_description"
        name="description"
        content={metaDescription}
      />
      <meta key="robots" name="robots" content={robots} />
      <meta name="googlebot" content={robots} />

      <meta key="og:url" property="og:url" content={pageUrl} />
      <link rel="canonical" href={pageUrl} />

      <meta key="og:title" property="og:title" name="title" content={ogTitle} />
      <meta
        key="og:type"
        property="og:type"
        name="type"
        content={settingsData.og_type || "website"}
      />
      <meta
        key="og:sitename"
        property="og:sitename"
        name="sitename"
        content={settingsData.og_site_name || "Notive"}
      />
      <meta
        key="og:description"
        property="og:description"
        content={ogDescription}
      />
      <meta key="og:image" property="og:image" content={ogImage} />

      <meta
        key="twitter:card"
        property="twitter:card"
        content="summary_large_image"
      />

      <meta key="twitter:url" property="twitter:url" content={pageUrl} />

      <meta
        key={"twitter_image"}
        property="twitter:image"
        content={twitterImage}
      />
      <meta
        key="twitter:title"
        property="twitter:title"
        content={twitterTitle}
      />
      <meta
        key="twitter:description"
        property="twitter:description"
        content={twitterDescription}
      />

      <meta key="og:locale" property="og:locale" content="nl_NL" />

      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon-16x16.png"
      />
      <link rel="manifest" href="/site.webmanifest" />
      <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
      <meta name="msapplication-TileColor" content="#da532c" />
      <meta name="theme-color" content="#ffffff" />
    </Head>
  );
};

export default MetaFields;
