import React from "react";
import Head from "next/head";

interface Parent {
  slug: string;
  meta_title: string;
  type: string;
  uid: string;
}

interface Data {
  meta_title: string;
  meta_description: string;
  parent?: Parent;
  webpage_description?: string;
}

interface PageData {
  uid: string;
  data: Data;
  type: string;
}

interface GlobalSettingsData {
  data: {
    description: string;
    website_description: string;
    website_logo: {
      url: string;
    };
    domain: string;
  };
}

interface LDJsonProps {
  page: PageData;
  globals: GlobalSettingsData;
}

const LDJson: React.FC<LDJsonProps> = ({ page, globals }) => {
  const Address = {
    "@type": "PostalAddress",
    streetAddress: "Scheepmakerij 220",
    addressLocality: "Zwijndrecht",
    addressRegion: "Zuid-Holland",
    postalCode: "3331MB",
    addressCountry: "Nederland",
  };

  const ImageLogo = {
    "@type": "ImageObject",
    url: globals.data?.website_logo?.url,
    height: "50", // not sure how to get the actual width and height
    width: "248",
  };

  const SameAs = [
    "https://twitter.com/Notive_NL",
    "https://www.facebook.com/notivenl",
    "https://www.linkedin.com/company/2621059",
    "https://www.instagram.com/notive",
    "https://github.com/Notive-BV",
  ];

  const Description = globals.data.description || "";
  const DescriptionWebsite = globals.data.website_description || "";
  const DescriptionWebpage = page.data.webpage_description || "";
  const Domain = globals.data.domain || "www.notive.nl";

  const MapUrl =
    "https://maps.google.com/maps?q=Notive+-+IT+innovation+partner%2C+Scheepmakerij+220%2C+Zwijndrecht%2C+Zuid-Holland+3331MB%2C+Nederland";
  const Phone = "+31103400242";

  const ContactPoint = [
    {
      "@type": "ContactPoint",
      telephone: "+311030400242",
      contactType: "customer support",
    },
    {
      "@type": "ContactPoint",
      telephone: "+311030400242",
      contactType: "technical support",
    },
    {
      "@type": "ContactPoint",
      telephone: "+311030400242",
      contactType: "billing support",
    },
    {
      "@type": "ContactPoint",
      telephone: "+311030400242",
      contactType: "sales",
    },
  ];

  const Place = {
    "@context": "https://schema.org",
    "@type": "Place",
    name: "Notive - IT Innovation Partner",
    alternateName: "Notive",
    description: Description,
    hasMap: MapUrl,
    telephone: Phone,
    image: ImageLogo,
    logo: ImageLogo,
    url: `https://${Domain}`,
    sameAs: SameAs,
    geo: {
      "@type": "GeoCoordinates",
      latitude: "51.81296949999999",
      longitude: "4.6485008",
    },
    address: Address,
  };

  const Corporation = {
    "@context": "https://schema.org",
    "@type": "Corporation",
    name: "Notive B.V.",
    alternateName: "Notive",
    description: Description,
    url: `https://${Domain}`,
    sameAs: SameAs,
    image: ImageLogo,
    telephone: Phone,
    email:
      "&#105;&#110;&#102;&#111;&#64;&#110;&#111;&#116;&#105;&#118;&#101;&#46;&#110;&#108;",
    address: Address,
    logo: ImageLogo,
    location: Place,
    duns: "491565863",
    founder: "Mitchell de Waard",
    foundingDate: "12 Mei 2012",
    foundingLocation: "Nederland",
    contactPoint: ContactPoint,
  };

  const Organization = {
    "@type": "Organization",
    name: "Notive B.V.",
    alternateName: "Notive",
    description: Description,
    url: `https://${Domain}`,
    image: ImageLogo,
    telephone: Phone,
    email:
      "&#105;&#110;&#102;&#111;&#64;&#110;&#111;&#116;&#105;&#118;&#101;&#46;&#110;&#108;",
    address: Address,
    logo: ImageLogo,
    location: Place,
    duns: "491565863",
    founder: "Mitchell de Waard",
    foundingDate: "12 Mei 2012",
    foundingLocation: "Nederland",
    contactPoint: ContactPoint,
  };

  const Website = {
    "@context": "https://schema.org",
    "@type": "WebSite",
    name: "Internetbureau Notive",
    description: DescriptionWebsite,
    url: `https://${Domain}`,
    sameAs: SameAs,
    copyrightHolder: Corporation,
    author: Corporation,
    creator: Organization,
  };

  const Breadcrumblist = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: "1",
        item: {
          "@id": `https://${Domain}`,
          name: "Homepage",
        },
      },
      ...(() => {
        if (page.uid === "home") {
          return [];
        }
        if (page.data.parent && page.data.parent.uid) {
          return [
            {
              "@type": "ListItem",
              position: "2",
              item: {
                "@id": `https://${Domain}/` + page.data.parent.slug,
                name: page.data.parent.meta_title,
              },
            },
            {
              "@type": "ListItem",
              position: "3",
              item: {
                "@id":
                  `https://${Domain}/` + page.data.parent.slug + "/" + page.uid,
                name: page.data.meta_title,
              },
            },
          ];
        } else {
          return [
            {
              "@type": "ListItem",
              position: "2",
              item: {
                "@id": `https://${Domain}/` + page.uid,
                name: page.data.meta_title,
              },
            },
          ];
        }
      })(),
    ],
  };

  const Webpage = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    name: page.data.meta_title,
    description: DescriptionWebpage,
    image: ImageLogo,
    url: `https://${Domain}/shopify/shopify-kosten`,
    mainEntityOfPage: `https://${Domain}/shopify/shopify-kosten`,
    inLanguage: "nl",
    headline: page.data.meta_title,
    keywords: "Notive",
    dateCreated: "2020-11-20T14:24:16+0000",
    dateModified: "2021-05-12T07:33:01+0000",
    datePublished: "2020-11-20T14:35:00+0000",
    copyrightYear: "2020",
    author: Corporation,
    copyrightHolder: Corporation,
    publisher: Organization,
    breadcrumb: Breadcrumblist,
  };

  const output = [
    Place,
    Corporation,
    Organization,
    Website,
    Breadcrumblist,
    Webpage,
  ];

  return (
    <Head>
      {output.map((item, index) => (
        <script
          key={index}
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: JSON.stringify(item) }}
        />
      ))}
    </Head>
  );
};

export default LDJson;
